/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _toLower from "lodash/toLower";

import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import ModelDelete from "../../components/ModalDelete";
import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelect2 from "../../components/FormSelect2";
import FormDatePicker from "../../components/FormDatePicker";
import ButtonActions from "../../components/ButtonActions";
import ToolTipInfo from "../../components/ToolTipInfo";

import {
  InfoButton,
  GreyButton,
  ButtonGroup,
  AInfoLink,
  InverseButton,
  ErrorButton,
  AIndigoLink,
} from "../../styles/button";
import { SuccessTag, GreyTag } from "../../styles/tag";
import { WrapWord } from "../../styles/misc";
import { WarningBox } from "../../styles/message";

import { isArrayExists } from "../../helpers/validation";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import {
  cloneCollections,
  doPagination,
  doArraySearch,
  getSelectOptions,
  getSelectValues,
  getSelectedValue,
} from "../../helpers/data";
import { getMomentTime, getMomentTimestamp } from "../../helpers/date";
import {
  isAdmin,
  hasAccessRights,
  getUserRegionFilter,
} from "../../helpers/auth";
import { callFunctionsAPI } from "../../helpers/action";

import { deleteSchedule } from "../../actions/schedules";

import { courseSchema } from "../../schemas/course";

import { PLATFORM_COURSE_REGISTRATION_LINK } from "../../constants";

const checkForDeleteCondition = (schedule_id) => {
  return new Promise((resolve, reject) => {
    callFunctionsAPI({
      url: "schedule",
      action: "check_delete_schedule",
      formData: {
        id: schedule_id,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

class Schedules extends React.Component {
  state = {
    checked: [],
    allCheck: false,
    searchterms: "",
    filterStartDate: 0,
    filterEndDate: 0,
    filterByRegion: "all",
    filterByCoordinator: "all",
    filterByVenue: "all",
    sortBy: "date-desc",
    perPage: 20,
    page: 1,
    openViewModal: false,
    modalData: false,
    openDeleteModal: false,
    deleteModal: false,
    checkDeleteDone: false,
    checkDeleteStatus: null,
    randNum: false,
  };

  componentDidMount() {
    // const { authData, start_date, end_date, randNum } = this.props;
    // this.setState({ filterStartDate: ( start_date || 0 ), filterEndDate: ( end_date || 0 ), randNum, filterByRegion: ( isAdmin( authData ) ? 'all' : getUserRegionFilter(authData) ) });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // const { authData, start_date, end_date, randNum } = this.props;
    // if ( randNum && randNum !== this.state.randNum ) {
    //     this.setState({ filterStartDate: ( start_date || 0 ), filterEndDate: ( end_date || 0 ), randNum });
    // } // end - randNum
  }

  //*incomplete - don't need this anymore'
  // handlePageRefresh = (event) => {
  //     event.preventDefault();
  //     if ( this.props.onRefresh )
  //         this.props.onRefresh();
  // }

  handleEdit = (id, event) => {
    event.preventDefault();
    this.props.history.push("/schedules/" + id);
  };

  handleDelete = () => {
    const { authData, start_date, end_date } = this.props;
    const { deleteModal } = this.state;
    // perform delete
    this.props.dispatch(
      deleteSchedule(
        { id: deleteModal.id },
        { start_date, end_date, region: getUserRegionFilter(authData) }
      )
    );
  };

  handleSchedulesFilter = () => {
    const { onFilter } = this.props;
    const {
      filterStartDate,
      filterEndDate,
      filterByRegion,
      filterByCoordinator,
      filterByVenue,
    } = this.state;
    let error = false;

    // do error check
    if (filterStartDate > filterEndDate)
      error = "Start Date cannot be after End Date";

    if (filterEndDate < filterStartDate)
      error = "End Date cannot be before Start Date";

    if (error) {
      triggerErrorAlert(error);
    } else {
      this.setState({
        openViewModal: false,
        filterStartDate: 0,
        filterEndDate: 0,
        filterByRegion: "all",
        filterByCoordinator: "all",
        filterByVenue: "all",
        page: 1,
      });
      onFilter({
        start_date: filterStartDate,
        end_date: filterEndDate,
        filter_region: filterByRegion || "all",
        filter_coordinator: filterByCoordinator || "all",
        filter_venue: filterByVenue || "all",
      });
    } // end - error
  };

  handleFilterChange = (newValue, key) => {
    const { filterStartDate, filterEndDate } = this.state;
    switch (key) {
      case "start_date":
        if (newValue < filterEndDate) {
          this.setState({ filterStartDate: newValue });
        } else {
          triggerErrorAlert("Start Date cannot be after End Date");
        }
        break;
      case "end_date":
        if (newValue > filterStartDate) {
          this.setState({ filterEndDate: newValue });
        } else {
          triggerErrorAlert("End Date cannot be before Start Date");
        }
        break;
      case "region":
        this.setState({ filterByRegion: newValue });
        break;
      case "coordinator":
        this.setState({ filterByCoordinator: newValue });
        break;
      case "training_location_id":
        this.setState({ filterByVenue: newValue });
        break;
    }
  };

  getQueryProps = () => {
    const { sortBy, perPage, page } = this.state;
    return false;
  };

  getSchedulesData = (schedules) => {
    const { regions, users } = this.props;
    let list = [];
    if (schedules && isArrayExists(schedules)) {
      _forEach(schedules, (schedule) => {
        let item = { ...schedule };
        item.partner_label = getSelectedValue(
          regions,
          schedule.region,
          "id",
          "label",
          ""
        );
        item.principal_name = getSelectedValue(
          users,
          schedule.principle,
          "email",
          "name",
          ""
        );
        item.coordinator_name = getSelectedValue(
          users,
          schedule.coordinator,
          "email",
          "name",
          ""
        );
        item.class_day_time =
          getMomentTime(schedule.start_date, "dddd") +
          " " +
          getMomentTime(schedule.start_date, "HH:mm") +
          " - " +
          getMomentTime(schedule.end_date, "HH:mm");
        item.start_date_label = getMomentTime(
          schedule.start_date,
          "MMM D, YYYY"
        );
        list.push(item);
      });
    }
    return list;
  };

  reorganizeData() {
    const { searchterms, sortBy, perPage, page } = this.state;
    const {
      schedules,
      filter_region,
      filter_coordinator,
      filter_venue,
      regions,
      users,
    } = this.props;
    let items = schedules ? this.getSchedulesData(schedules) : [],
      total = _size(items);

    // do search
    if (searchterms && !_isEmpty(searchterms)) {
      items = doArraySearch(items, searchterms, [
        "class_name",
        "partner_label",
        "principal_name",
        "coordinator_name",
        "training_location_label",
        "class_day_time",
        "start_date_label",
      ]);
      total = _size(items);
    } // end - searchterms

    // do region filter
    if (filter_region && !_isEmpty(filter_region) && filter_region !== "all") {
      items = _filter(items, { region: filter_region });
      total = _size(items);
    } // end - filter_region

    // do coordinator filter
    if (
      filter_coordinator &&
      !_isEmpty(filter_coordinator) &&
      filter_coordinator !== "all"
    ) {
      items = _filter(items, { coordinator: filter_coordinator });
      total = _size(items);
    } // end - filter_coordinator

    // do venue filter
    if (filter_venue && !_isEmpty(filter_venue) && filter_venue !== "all") {
      items = _filter(items, { training_location_id: filter_venue });
      total = _size(items);
    } // end - filter_venue

    // do sort
    if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
      switch (sortBy) {
        case "date-desc":
          items = _sortBy(items, ["start_date"]);
          items = _reverse(items);
          break;
        case "date-asc":
          items = _sortBy(items, ["start_date"]);
          break;
        case "class_name-desc":
          items = _sortBy(items, ["class_name"]);
          items = _reverse(items);
          break;
        case "class_name-asc":
          items = _sortBy(items, ["class_name"]);
          break;
        case "principle-desc":
          items = _sortBy(items, (i) =>
            _toLower(getSelectedValue(users, i.principle, "email", "name", ""))
          );
          items = _reverse(items);
          break;
        case "principle-asc":
          items = _sortBy(items, (i) =>
            _toLower(getSelectedValue(users, i.principle, "email", "name", ""))
          );
          break;
        case "coordinator-desc":
          items = _sortBy(items, (i) =>
            _toLower(
              getSelectedValue(users, i.coordinator, "email", "name", "")
            )
          );
          items = _reverse(items);
          break;
        case "coordinator-asc":
          items = _sortBy(items, (i) =>
            _toLower(
              getSelectedValue(users, i.coordinator, "email", "name", "")
            )
          );
          break;
        case "training_location_label-desc":
          items = _sortBy(items, ["training_location_label"]);
          items = _reverse(items);
          break;
        case "training_location_label-asc":
          items = _sortBy(items, ["training_location_label"]);
          break;
        case "class_day_time-desc":
          items = _sortBy(
            items,
            (i) =>
              (getMomentTime(i.start_date, "d") === 0 ||
              getMomentTime(i.start_date, "d") === "0"
                ? 7
                : getMomentTime(i.start_date, "d")) +
              getMomentTime(i.start_date, "HHmm")
          );
          items = _reverse(items);
          break;
        case "class_day_time-asc":
          items = _sortBy(
            items,
            (i) =>
              (getMomentTime(i.start_date, "d") === 0 ||
              getMomentTime(i.start_date, "d") === "0"
                ? 7
                : getMomentTime(i.start_date, "d")) +
              getMomentTime(i.start_date, "HHmm")
          );
          break;
        case "total_registered-desc":
          items = _sortBy(items, ["total_registered"]);
          items = _reverse(items);
          break;
        case "total_registered-asc":
          items = _sortBy(items, ["total_registered"]);
          break;
        case "region-desc":
          items = _sortBy(items, (i) =>
            _toLower(getSelectedValue(regions, i.region, "id", "label", ""))
          );
          items = _reverse(items);
          break;
        case "region-asc":
          items = _sortBy(items, (i) =>
            _toLower(getSelectedValue(regions, i.region, "id", "label", ""))
          );
          break;
      }
    } // end - sortBy

    // do pagination
    items = doPagination(items, perPage, page);

    return { items, total };
  }

  handleDeleteCheck = (schedule) => {
    this.setState({
      openDeleteModal: true,
      deleteModal: schedule,
      checkDeleteDone: false,
    });
    checkForDeleteCondition(schedule.id)
      .then((data) => {
        this.setState({ checkDeleteDone: true, checkDeleteStatus: data });
      })
      .catch((error) => {
        triggerErrorAlert(error);
        this.setState({
          checkDeleteDone: true,
          openDeleteModal: false,
          deleteModal: null,
        });
      });
  };

  renderDeleteContent = () => {
    const { checkDeleteStatus, checkDeleteDone, deleteModal } = this.state;
    const activeStudents =
      checkDeleteStatus && checkDeleteStatus.activeStudents
        ? checkDeleteStatus.activeStudents
        : 0;
    const pendingNominations =
      checkDeleteStatus &&
      checkDeleteStatus.showcaseProjects &&
      checkDeleteStatus.showcaseProjects.pending_nomination
        ? checkDeleteStatus.showcaseProjects.pending_nomination.length
        : 0;
    const pendingApprovals =
      checkDeleteStatus &&
      checkDeleteStatus.showcaseProjects &&
      checkDeleteStatus.showcaseProjects.pending_approval
        ? checkDeleteStatus.showcaseProjects.pending_approval.length
        : 0;
    return checkDeleteDone ? (
      <div>
        <p style={{ marginBottom: "10px" }}>This action cannot be reversed.</p>
        {activeStudents > 0 ||
        pendingNominations > 0 ||
        pendingApprovals > 0 ? (
          <WarningBox>
            <p>Please check the following conditions before deleting:</p>
            <ul
              style={{
                marginLeft: "20px",
                listStyleType: "disc",
              }}
            >
              {activeStudents > 0 && (
                <li>This class has {activeStudents} active students.</li>
              )}
              {pendingNominations > 0 || pendingApprovals > 0 ? (
                <li>
                  This class has showcase projects yet to be reviewed:
                  <ul style={{ marginLeft: "20px", listStyleType: "disc" }}>
                    {pendingNominations > 0 && (
                      <li>{pendingNominations} pending for nominations</li>
                    )}
                    {pendingApprovals > 0 && (
                      <li>{pendingApprovals} pending for approvals</li>
                    )}
                  </ul>
                </li>
              ) : null}
            </ul>
            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
              {activeStudents > 0 && (
                <a
                  href={`/r/${deleteModal.id}?location=course_access`}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push(
                      `/r/${deleteModal.id}?location=course_access`
                    );
                  }}
                >
                  Deactivate students
                </a>
              )}
              {pendingNominations > 0 ||
                (pendingApprovals > 0 && (
                  <a
                    href={`/r/${deleteModal.id}?location=showcase`}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(
                        `/r/${deleteModal.id}?location=showcase`
                      );
                    }}
                  >
                    Review Badges
                  </a>
                ))}
            </div>
          </WarningBox>
        ) : null}
        <p style={{ marginTop: "20px" }}>
          By deleting this class, you are about to…
        </p>
        <ul
          style={{
            marginLeft: "20px",
            listStyleType: "disc",
          }}
        >
          <li>
            Deactivate all the students in this class, without badges awarded.
          </li>
          <li>Deactivate all active students on their active courses</li>
          <li>Remove all mentors from the class in LMS</li>
        </ul>
      </div>
    ) : (
      <div></div>
    );
  };

  renderCell = (type) => (item) => {
    const { regions, users, locations } = this.props;
    switch (type) {
      case "start_date":
        return getMomentTime(item[type], "DD MMM YYYY");
      case "day_time":
        return (
          <>
            <div>{getMomentTime(item["start_date"], "dddd")}</div>
            <div>
              {getMomentTime(item["start_date"], "HH:mm") +
                " - " +
                getMomentTime(item["end_date"], "HH:mm")}
            </div>
          </>
        );
      case "rolling_class":
        return item[type] && item[type] === "yes" ? (
          <CheckBoxIcon />
        ) : (
          <CheckBoxOutlineBlankIcon />
        );
      case "principle":
      case "coordinator":
        return getSelectedValue(users, item[type], "email", "name", "-");
      case "region":
        return getSelectedValue(regions, item[type], "id", "label", "-");
      case "email":
        return <WrapWord>{item[type] || ""}</WrapWord>;
      case "reglink":
        return (
          <ButtonGroup>
            <CopyToClipboard
              text={PLATFORM_COURSE_REGISTRATION_LINK + item.id}
              onCopy={() => {
                triggerSuccessAlert("Link copied to clipboard.");
              }}
            >
              <InverseButton
                size="small"
                style={{ padding: "4px 8px" }}
                minWidth="0px"
                noIconMargin="yes"
              >
                <i className="fa fa-copy"></i>
              </InverseButton>
            </CopyToClipboard>
            <AInfoLink
              href={PLATFORM_COURSE_REGISTRATION_LINK + item.id}
              target="_blank"
              minWidth="0px"
              size="small"
              style={{ padding: "4px 8px" }}
              noIconMargin="yes"
            >
              <i className="fa fa-globe"></i>
            </AInfoLink>
          </ButtonGroup>
        );
    }
  };

  renderFilterForm = () => {
    const { authData, users, regions, locations } = this.props;
    const {
      filterStartDate,
      filterEndDate,
      filterByRegion,
      filterByVenue,
      filterByCoordinator,
    } = this.state;
    return (
      <div>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6}>
            <FormDatePicker
              InputAdornmentProps={{ disabled: true }}
              value={filterStartDate}
              label="From"
              name="start_date"
              onChange={this.handleFilterChange}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDatePicker
              InputAdornmentProps={{ disabled: true }}
              value={filterEndDate}
              label="Until"
              name="end_date"
              onChange={this.handleFilterChange}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ paddingTop: "7px" }}>
              <FormSelect
                value={filterByRegion}
                label="Partner"
                name="region"
                options={getSelectOptions({
                  list: _sortBy(regions, ["label"]),
                  options: [{ value: "all", label: "All Partners" }],
                  keys: { value: "id", label: "label" },
                })}
                disabled={isAdmin(authData) ? false : true}
                onChange={this.handleFilterChange}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ paddingTop: "7px" }}>
              <FormSelect
                value={filterByCoordinator}
                label="Coordinator"
                name="coordinator"
                options={getSelectOptions({
                  list: _sortBy(users, ["name"]),
                  options: [{ value: "all", label: "All Coordinators" }],
                  keys: { value: "email", label: "name" },
                })}
                onChange={this.handleFilterChange}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ paddingTop: "7px" }}>
              <FormSelect
                value={filterByVenue}
                label="Venue"
                name="training_location_id"
                options={getSelectOptions({
                  list: _sortBy(locations, ["name"]),
                  options: [{ value: "all", label: "All Venues" }],
                  keys: { value: "id", label: "name" },
                })}
                onChange={this.handleFilterChange}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderLeftButtons = () => {
    const { authData, users, regions, locations } = this.props;
    const {
      start_date,
      end_date,
      filter_region,
      filter_coordinator,
      filter_venue,
    } = this.props;
    let tagStyle = { marginRight: "5px", backgroundColor: "#ddd" },
      buttons = [];

    buttons.push(
      <GreyTag key="date" style={tagStyle}>
        {"From " +
          getMomentTime(start_date, "YYYY-MM-DD") +
          " Until " +
          getMomentTime(end_date, "YYYY-MM-DD")}
      </GreyTag>
    );

    buttons.push(
      <GreyTag key="region" style={tagStyle}>
        Partner:{" "}
        {filter_region && filter_region === "all"
          ? "All"
          : getSelectedValue(regions, filter_region, "id", "label", "-")}
      </GreyTag>
    );

    buttons.push(
      <GreyTag key="coordinator" style={tagStyle}>
        Coordinator:{" "}
        {filter_coordinator && filter_coordinator === "all"
          ? "All"
          : getSelectedValue(users, filter_coordinator, "email", "name", "-")}
      </GreyTag>
    );

    buttons.push(
      <GreyTag key="training_location_id" style={tagStyle}>
        Venue:{" "}
        {filter_venue && filter_venue === "all"
          ? "All"
          : getSelectedValue(locations, filter_venue, "id", "name", "-")}
      </GreyTag>
    );

    buttons.push(
      <InfoButton
        size="small"
        key="filter"
        style={{ padding: "5px 8px" }}
        onClick={() =>
          this.setState({
            openViewModal: true,
            filterStartDate: start_date || 0,
            filterEndDate: end_date || 0,
            filterByRegion: filter_region || "all",
            filterByCoordinator: filter_coordinator || "all",
            filterByVenue: filter_venue || "all",
          })
        }
      >
        <i className="fa fa-filter"></i>Customize Filter
      </InfoButton>
    );
    return buttons;
  };

  renderTableActions = () => {
    const { authData, course_type } = this.props;
    const { sortBy, perPage, searchterms, checked } = this.state;
    return (
      <TableBar
        sortBy={sortBy}
        show={["entries", "sort", "search"]}
        perPage={perPage}
        searchterms={searchterms}
        sortByOptions={[
          { value: "date-desc", label: "Start Date (Recent First)" },
          { value: "date-asc", label: "Start Date (Oldest First)" },
          { value: "class_name-asc", label: "Class Name ( A - Z)" },
          { value: "class_name-desc", label: "Class Name ( Z - A )" },
          { value: "principle-asc", label: "Principal ( A - Z)" },
          { value: "principle-desc", label: "Principal ( Z - A )" },
          { value: "coordinator-asc", label: "Coordinator ( A - Z)" },
          { value: "coordinator-desc", label: "Coordinator ( Z - A )" },
          { value: "training_location_label-asc", label: "Venue ( A - Z)" },
          { value: "training_location_label-desc", label: "Venue ( Z - A )" },
          {
            value: "class_day_time-asc",
            label: "Class Day & Time ( Mon - Sun )",
          },
          {
            value: "class_day_time-desc",
            label: "Class Day & Time ( Sun - Mon )",
          },
          {
            value: "total_registered-asc",
            label: "Class Size ( Lowest number first )",
          },
          {
            value: "total_registered-desc",
            label: "Class Size ( Highest number first )",
          },
          { value: "region-asc", label: "Partner ( A - Z)" },
          { value: "region-desc", label: "Partner ( Z - A )" },
        ]}
        leftButtons={[]}
        leftWidth="70%"
        rightWidth="30%"
        rightButtons={[
          <InfoButton
            minWidth="168px"
            key="addnew"
            style={{ marginRight: "5px" }}
            onClick={() => {
              this.props.history.push("/schedules/new");
            }}
          >
            <i className="fa fa-plus-circle"></i>Add New
          </InfoButton>,
        ]}
        onEntriesChange={(newPerPage) =>
          this.setState({ perPage: newPerPage, page: 1 })
        }
        onSearchChange={(terms) =>
          this.setState({ searchterms: terms, page: 1 })
        }
        onSortByChange={(newSortBy) =>
          this.setState({ sortBy: newSortBy, page: 1 })
        }
        style={{ marginBottom: "20px" }}
      />
    );
  };

  renderPagination = (totalCount) => {
    const { perPage, page } = this.state;
    return (
      <Pagination
        total={totalCount}
        perPage={perPage}
        page={page}
        style={{ marginTop: "20px" }}
        doneLoaded={true}
        onPageChange={(newPage) => this.setState({ page: newPage })}
      />
    );
  };

  render() {
    const { authData, users } = this.props;
    const {
      openDeleteModal,
      deleteModal,
      openViewModal,
      allCheck,
      checked,
      checkDeleteDone,
      checkDeleteStatus,
    } = this.state;
    const { items, total } = this.reorganizeData();
    return (
      <div>
        <ModalView
          open={openViewModal}
          title="Filter Class Schedule"
          onClose={() =>
            this.setState({
              openViewModal: false,
              filterStartDate: 0,
              filterEndDate: 0,
              filterByRegion: "all",
              filterByCoordinator: "all",
              filterByVenue: "all",
            })
          }
          actionLabel="Filter"
          doAction={this.handleSchedulesFilter}
          contents={this.renderFilterForm()}
        />

        {authData && hasAccessRights(authData, ["scd"]) ? (
          <ModelDelete
            open={openDeleteModal}
            title={
              deleteModal &&
              deleteModal.class_name &&
              !_isEmpty(deleteModal.class_name)
                ? `Are you sure you want to delete this class schedule ( ${deleteModal.class_name} )?`
                : false
            }
            processing={checkDeleteDone ? false : true}
            deletingLabel={!checkDeleteDone ? "Loading..." : "Deleting..."}
            hideDeleteButton={
              !(checkDeleteStatus && checkDeleteStatus.canDelete) ? true : false
            }
            cancelLabel={
              checkDeleteStatus && checkDeleteStatus.canDelete
                ? "Cancel"
                : "Close"
            }
            fullContent={this.renderDeleteContent()}
            onClose={() =>
              this.setState({ openDeleteModal: false, deleteModal: false })
            }
            onDelete={this.handleDelete}
          />
        ) : null}

        {this.renderLeftButtons()}
        {this.renderTableActions()}
        <Table
          items={items}
          showCheckbox={false}
          checked={checked || []}
          allCheck={allCheck}
          onChecked={(newValue) => this.setState({ checked: newValue })}
          onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
          cells={[
            {
              id: "region",
              label: "Partner",
              render: this.renderCell("region"),
            },
            {
              id: "class_name",
              label: "Class Name",
              render: (item) => <WrapWord>{item.class_name || "-"}</WrapWord>,
            },
            {
              id: "principle",
              label: "Principal",
              render: this.renderCell("principle"),
            },
            {
              id: "coordinator",
              label: "Coordinator",
              render: this.renderCell("coordinator"),
            },
            {
              id: "training_location_label",
              label: "Venue",
              render: (item) => item.training_location_label || "",
            },
            {
              id: "day_time",
              label: "Class Day & Time",
              render: this.renderCell("day_time"),
            },
            {
              id: "rolling_class",
              label: "Rolling Basis",
              render: this.renderCell("rolling_class"),
            },
            {
              id: "start_date",
              label: "Start Date",
              render: this.renderCell("start_date"),
            },
            {
              id: "total_registered",
              label: (
                <>
                  {/* <ToolTipInfo 
                        content={(
                        <>
                            TOTAL NUMBER OF ACTIVE STUDENTS CURRENTLY<br />
                            This number is gathered from <a href="#" onClick={event => {
                                const { history } = this.props;
                                event.preventDefault();
                                history.push('/course_access');
                            }}>Course Access</a>. {"When whole class status is active, this class cannot be deleted."}
                        </>
                        )} /> */}
                  {"Whole Class Status"}
                </>
              ),
              render: (item) => (item.total_registered ? "Active" : "Inactive"),
            },
            {
              id: "reg_link",
              label: "Registration Link",
              render: this.renderCell("reglink"),
            },
          ]}
          actionStyles={{ width: "10%" }}
          actions={(item) => (
            <ButtonGroup>
              <AInfoLink
                key="edit"
                href={"/schedules/" + item.id}
                size="small"
                onClick={this.handleEdit.bind(this, item.id)}
              >
                <i className="fa fa-edit"></i>Edit
              </AInfoLink>
              <AIndigoLink
                key="duplicate"
                href={"/schedules/new?duplicate_id=" + item.id}
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(
                    "/schedules/new?duplicate_id=" + item.id
                  );
                }}
              >
                <i className="fa fa-copy"></i>Duplicate
              </AIndigoLink>
              {authData ? (
                <ErrorButton
                  key="delete"
                  size="small"
                  onClick={() => this.handleDeleteCheck(item)}
                >
                  <i className="fa fa-trash"></i>Delete
                </ErrorButton>
              ) : null}
            </ButtonGroup>
          )}
        />
        {this.renderPagination(total)}
      </div>
    );
  }
}

export default compose(connect(), withRouter)(Schedules);
