/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _remove from "lodash/remove";
import _isEqual from "lodash/isEqual";
import _startsWith from "lodash/startsWith";
import _replace from "lodash/replace";
import _toUpper from "lodash/toUpper";

import AppWrapper from "../../components/AppWrapper";
import DotsLoader from "../../components/DotsLoader";
import SnackBarSave from "../../components/SnackBarSave";
import ScheduleSwitcher from "../../components/ScheduleSwitcher";

import EditForm from "./form";
import ItemNotFound from "../../components/ItemNotFound";

import { InfoButton, GreyButton } from "../../styles/button";

import {
  hasAccessRights,
  getUserRegionFilter,
  isAdmin,
} from "../../helpers/auth";
import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { cloneCollections } from "../../helpers/data";
import { triggerErrorAlert } from "../../helpers/alert";
import { doSchemaErrorCheck } from "../../helpers/schemas";

import {
  getSchedule,
  editSchedule,
  getGoogleCalendar,
} from "../../actions/schedules";
import { getCoursesOptions } from "../../actions/courses";
import { getLMSCourses } from "../../actions/canvas_lms";
import { getUsersOptions } from "../../actions/users";
import { getTrainingLocationsOptions } from "../../actions/training_locations";
import { getRegions } from "../../actions/m_regions";
import { getMaintenance } from "../../actions/maintenance";
import { appChangesMade, resetRedux } from "../../actions/misc";

import { scheduleSchema } from "../../schemas/schedule";

class SchedulePage extends React.Component {
  utcOffSet = 8;

  state = {
    currentTab: "details",
    schedule: false,
    randNum: false,
  };

  componentDidMount() {
    const {
      authData,
      regionsList,
      usersOptionsList,
      contactHourList,
      productLevelList,
      productCreditList,
      courseTypeList,
      courseDurationList,
      pricePerPaxList,
      venueList,
      coursesList,
      calendarEventsList,
      lmsCoursesList,
    } = this.props;
    const { schedule_id } = this.props.match.params;

    // retrieve schedule details
    this.props.dispatch(
      getSchedule(_toUpper(schedule_id), { authData, getScheduleUsage: true })
    );

    // get regions
    if (!regionsList) this.props.dispatch(getRegions());

    // get users options
    if (!usersOptionsList)
      this.props.dispatch(
        getUsersOptions({ region: getUserRegionFilter(authData) })
      );

    // get contact hour
    if (!contactHourList) this.props.dispatch(getMaintenance("contact_hour"));

    // get product level
    if (!productLevelList) this.props.dispatch(getMaintenance("product_level"));

    // get product credit
    if (!productCreditList)
      this.props.dispatch(getMaintenance("product_credit"));

    // get course type
    if (!courseTypeList) this.props.dispatch(getMaintenance("course_type"));

    // get course duration
    if (!courseDurationList)
      this.props.dispatch(getMaintenance("course_duration"));

    // get price per pax list
    if (!pricePerPaxList) this.props.dispatch(getMaintenance("price_per_pax"));

    // get venue options
    if (!venueList)
      this.props.dispatch(
        getTrainingLocationsOptions({ region: getUserRegionFilter(authData) })
      );

    // get course options
    // if ( !coursesList )
    this.props.dispatch(
      getCoursesOptions({ region: getUserRegionFilter(authData) })
    );

    // get lms courses
    if (!lmsCoursesList) this.props.dispatch(getLMSCourses());

    // get calendar events
    if (!calendarEventsList) this.props.dispatch(getGoogleCalendar());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      currentSchedule,
      randNum,
      regionsList,
      usersOptionsList,
      contactHourList,
      productLevelList,
      productCreditList,
      courseTypeList,
      courseDurationList,
      pricePerPaxList,
      venueList,
      coursesList,
      calendarEventsList,
      lmsCoursesList,
    } = this.props;

    // for schedule
    if (
      currentSchedule &&
      regionsList &&
      usersOptionsList &&
      contactHourList &&
      productLevelList &&
      productCreditList &&
      courseTypeList &&
      courseDurationList &&
      pricePerPaxList &&
      venueList &&
      coursesList &&
      calendarEventsList &&
      lmsCoursesList &&
      randNum &&
      randNum !== this.state.randNum
    ) {
      this.setState({ schedule: cloneCollections(currentSchedule), randNum });
    } // end - currentSchedule
  }

  componentWillUnmount() {
    this.props.dispatch(resetRedux("schedule"));
  }

  handleRefresh = (type) => {
    // const { schedule_id } = this.props.match.params;
    // switch( type ) {
    //     case 'emails':
    //         this.setState({ emails: false });
    //         this.props.dispatch(getEmailsBy('courses',schedule_id));
    //         break;
    // }
  };

  handleSaveChanges = (event) => {
    const { authData } = this.props;
    const { schedule } = this.state;
    event.preventDefault();
    var error = false,
      formData = {};

    // do error check
    scheduleSchema.forEach((schema) => {
      formData[schema.id] =
        schedule && schedule[schema.id]
          ? cloneCollections(schedule[schema.id])
          : schema.default;
      if (!doSchemaErrorCheck(formData[schema.id], schema, "update")) {
        error = 'Please fill out "' + schema.label + '" field';
      }
    });

    // check for start date & end date
    if (schedule.start_date > schedule.end_date) {
      error = "Start date cannot be after End date";
    }

    if (schedule.end_date < schedule.start_date) {
      error = "End date cannot be before Start date";
    }

    if (
      !(
        schedule &&
        schedule.class_calendar &&
        isArrayExists(schedule.class_calendar) &&
        _find(schedule.class_calendar, { showcase: "yes" })
      )
    ) {
      error =
        "Warning! You have yet to schedule any Open Day in Class Calendar.";
    }

    if (error) {
      triggerErrorAlert(error);
    } else {
      // remove past class in class calendar
      if (schedule.class_calendar && isArrayExists(schedule.class_calendar)) {
        let updateCalendar = [],
          today = moment().utcOffset(this.utcOffSet).startOf("date").valueOf();
        _forEach(schedule.class_calendar, (event) => {
          if (event.start_date && event.start_date >= today)
            updateCalendar.push(event);
        });
        formData.class_calendar = updateCalendar;
      } // end - schedule.class_calendar
      this.props.dispatch(
        editSchedule(formData, { authData, getScheduleUsage: true })
      );
    } // end - error
  };

  handleFormUpdate = (newValue) => {
    this.setState({ schedule: newValue });

    // trigger changes made
    this.props.dispatch(appChangesMade());
  };

  // handleTabChange = (newTab) => {
  //     const { schedule_id } = this.props.match.params;
  //     const { history } = this.props;
  //     this.setState({ currentTab: newTab });
  // }

  // getLinks = () => {
  //     const { authData } = this.props;
  //     let links = [
  //         { id: 'details', label: 'Course Info', icon: 'fa fa-cog' },
  //         { id: 'personnel', label: 'Personnel Database', icon: 'fa fa-users' }
  //     ];

  //     return links;
  // }

  renderTabContents = () => {
    const { schedule_id } = this.props.match.params;
    const {
      authData,
      currentSchedule,
      usersOptionsList,
      regionsList,
      contactHourList,
      productLevelList,
      productCreditList,
      venueList,
      pricePerPaxList,
      coursesList,
      calendarEventsList,
      lmsCoursesList,
      courseDurationList,
      courseTypeList,
      changesMade,
    } = this.props;
    const { currentTab, schedule } = this.state;
    const tabProps = {
      authData: authData || false,
      schedule: schedule || false,
      changesMade: changesMade || false,
      onFormUpdate: this.handleFormUpdate,
    };
    switch (currentTab) {
      case "details":
        return (
          <EditForm
            formType="update"
            currentSchedule={currentSchedule || false}
            users={usersOptionsList}
            contact_hour={contactHourList}
            product_level={productLevelList}
            product_credit={productCreditList}
            course_type={courseTypeList}
            course_duration={courseDurationList}
            regions={regionsList}
            locations={venueList}
            courses={coursesList}
            price_per_pax={pricePerPaxList}
            calendar_events={calendarEventsList}
            lms_courses={lmsCoursesList}
            // updateActions={[getSchedule(schedule_id)]}
            {...tabProps}
          />
        );
      // case 'emails':
      //     return ( emails ? <EmailsList
      //         type="courses"
      //         typeID={( schedule_id || false )}
      //         changesMade={changesMade}
      //         onRefresh={this.handleRefresh}
      //         {...tabProps} /> : <DotsLoader /> );
    }
  };

  // renderTabs = () => {
  //     const { currentTab } = this.state;
  //     const btnStyle = {
  //         textAlign: 'center',
  //         minWidth: '225px',
  //         padding: "10px 5px",
  //         marginRight: "5px"
  //     };
  //     return (
  //     <Box display="flex" justifyContent="center" alignItems="center">
  //         {this.getLinks().map((button,index) => {
  //             if ( button.id === currentTab) {
  //                 return <InfoButton key={index} style={btnStyle} onClick={event => event.preventDefault()}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</InfoButton>
  //             } else {
  //                 return <GreyButton key={index} style={btnStyle} onClick={this.handleTabChange.bind(this,button.id)}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</GreyButton>
  //             }
  //         })}
  //     </Box>
  //     )
  // }

  renderContents() {
    return (
      <div>
        {/* {this.renderTabs()} */}
        {this.renderTabContents()}

        <div style={{ textAlign: "center", marginTop: "45px" }}>
          <InfoButton
            style={{ padding: "15px 5px" }}
            minWidth="250px"
            onClick={this.handleSaveChanges}
          >
            <i className="fa fa-save"></i>Save Changes
          </InfoButton>
        </div>

        <SnackBarSave onSave={this.handleSaveChanges} />
      </div>
    );
  }

  render() {
    const { schedule_id } = this.props.match.params;
    const { randNum } = this.state;
    const { currentSchedule, authData } = this.props;
    return (
      <AppWrapper
        title="Edit Class Schedule"
        subtitle="1. Recruitment"
        back="/schedules"
        breadcrumbs={[
          { url: "/schedules", label: "Class Schedule" },
          { label: "Edit" },
        ]}
        customColumn={
          schedule_id && !_isEmpty(schedule_id) ? (
            <ScheduleSwitcher
              page="class_schedule"
              schedule_id={schedule_id}
              authData={authData}
            />
          ) : null
        }
        // maxWidth="1110px"
        onLoad={!randNum ? true : false}
        contents={
          currentSchedule &&
          currentSchedule.id &&
          !_isEmpty(currentSchedule.id) ? (
            this.renderContents()
          ) : (
            <ItemNotFound item="Class Schedule" />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authData: state.auth && state.auth.user ? state.auth.user : null,
    currentSchedule:
      state.schedules && state.schedules.schedule
        ? state.schedules.schedule
        : null,
    randNum:
      state.schedules && state.schedules.rand ? state.schedules.rand : null,
    usersOptionsList:
      state.users && state.users.users_options
        ? state.users.users_options
        : false,
    regionsList:
      state.maintenance && state.maintenance.regions
        ? state.maintenance.regions
        : false,
    venueList:
      state.training_locations && state.training_locations.locations_options
        ? state.training_locations.locations_options
        : false,
    courseTypeList:
      state.maintenance && state.maintenance.course_type
        ? state.maintenance.course_type
        : null,
    courseDurationList:
      state.maintenance && state.maintenance.course_duration
        ? state.maintenance.course_duration
        : null,
    pricePerPaxList:
      state.maintenance && state.maintenance.price_per_pax
        ? state.maintenance.price_per_pax
        : null,
    coursesList:
      state.courses && state.courses.courses_options
        ? state.courses.courses_options
        : false,
    calendarEventsList:
      state.schedules && state.schedules.calendar_events
        ? state.schedules.calendar_events
        : null,
    lmsCoursesList:
      state.canvas_lms && state.canvas_lms.courses
        ? state.canvas_lms.courses
        : null,
    contactHourList:
      state.maintenance && state.maintenance.contact_hour
        ? state.maintenance.contact_hour
        : null,
    productLevelList:
      state.maintenance && state.maintenance.product_level
        ? state.maintenance.product_level
        : null,
    productCreditList:
      state.maintenance && state.maintenance.product_credit
        ? state.maintenance.product_credit
        : null,
    changesMade:
      state.misc && state.misc.changes_made ? state.misc.changes_made : null,
  };
};

export default compose(connect(mapStateToProps), withRouter)(SchedulePage);
