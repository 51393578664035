/* eslint-disable */
import React from "react";
import styled from "styled-components";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import _isEmpty from "lodash/isEmpty";
import _toLower from "lodash/toLower";
import _replace from "lodash/replace";

import theme from "../../theme";

const DatePickerWrapper = styled.div`
  padding: 0px 5px;

  .MuiInputLabel-shrink {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

class FormDatePicker extends React.Component {
  utcFormat = 8;

  handleChange = (date, value) => {
    const { name } = this.props;
    if (this.props.onChange) {
      let newValue =
        date && moment.isMoment(date)
          ? date.utcOffset(this.utcFormat).valueOf()
          : 0;
      this.props.onChange(newValue, name);
    }
  };

  shouldDisableDate = (date) => {
    const { disableAfter } = this.props;
    return disableAfter && date.isAfter(moment.utc(disableAfter));
  };

  getValue = () => {
    const { value, noDefaultVal } = this.props;
    return value
      ? moment(value).utcOffset(this.utcFormat)
      : noDefaultVal
      ? null
      : moment().utcOffset(this.utcFormat);
  };

  renderLabel = () => {
    const { label } = this.props;
    var newLabel = false;
    if (
      label &&
      !_isEmpty(label) &&
      (_toLower(label).indexOf("(required)") >= 0 ||
        _toLower(label).indexOf("*") >= 0)
    ) {
      newLabel = _replace(label, "(Required)", "");
      newLabel = _replace(newLabel, "(required)", "");
      newLabel = _replace(newLabel, "*", "");
    } // end - label
    return newLabel ? (
      <span>
        {newLabel || ""}
        <span className="required">*</span>
      </span>
    ) : (
      label || ""
    );
  };

  render() {
    const { disabled, InputAdornmentProps, disablePast } = this.props;
    return (
      <DatePickerWrapper>
        <FormControl fullWidth={true}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              shouldDisableDate={this.shouldDisableDate}
              disableToolbar
              variant="inline"
              inputVariant={disabled ? "filled" : "outlined"}
              InputLabelProps={{
                shrink: true,
              }}
              InputAdornmentProps={InputAdornmentProps || null}
              format="DD/MM/YYYY"
              margin="normal"
              label={
                <span style={{ background: "#fff", paddingRight: "4px" }}>
                  {this.renderLabel()}
                </span>
              }
              value={this.getValue()}
              onChange={this.handleChange}
              disabled={disabled || false}
              disablePast={disablePast || false}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </DatePickerWrapper>
    );
  }
}

export default FormDatePicker;
