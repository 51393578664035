/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import shortid from "shortid";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import grey from "@material-ui/core/colors/grey";
import FullCalendar from "rc-calendar/lib/FullCalendar";
import RCSelect from "rc-select";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _sortBy from "lodash/sortBy";
import _filter from "lodash/filter";
import _size from "lodash/size";
import _reverse from "lodash/reverse";
import _remove from "lodash/remove";
import _snakeCase from "lodash/snakeCase";
import _toString from "lodash/toString";

import ModalView from "../../components/ModalView";
import ModelDelete from "../../components/ModalDelete";
import FormInput from "../../components/FormInput";
import FormCheckbox from "../../components/FormCheckbox";
import FormSelect from "../../components/FormSelect";
import FormDatePicker from "../../components/FormDatePicker";
import FormTimePicker from "../../components/FormTimePicker";
import FormEditor from "../../components/FormEditor";
import ButtonActions from "../../components/ButtonActions";
import ToolTipInfo from "../../components/ToolTipInfo";
import PopperBox from "../../components/Popper";

import {
  ButtonGroup,
  InverseButton,
  InfoButton,
  ErrorButton,
  GreyButton,
  SuccessButton,
} from "../../styles/button";
import { WrapWord } from "../../styles/misc";
import { ErrorBox } from "../../styles/message";

import { isAdmin, hasAccessRights } from "../../helpers/auth";
import { isArrayExists } from "../../helpers/validation";
import {
  cloneCollections,
  getSelectOptions,
  getSelectValues,
  getSelectedValue,
} from "../../helpers/data";
import { getMomentTime } from "../../helpers/date";
import { isSchemaRequired, getSchemaDefaultValue } from "../../helpers/schemas";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";

import { scheduleSchema } from "../../schemas/schedule";
import { classCalendarSchema } from "../../schemas/class_calendar";

import { toggleLoader } from "../../actions/global";

// import { MalaysiaPublicHoliday2021 } from '../../data/const_public_holiday_2021';
import { YES_NO_OPTIONS, DAY_OPTIONS, MONTH_OPTIONS } from "../../constants";

import theme from "../../theme";

const useStyles = (theme) => ({
  boxheading: {
    fontSize: "20px",
    fontWeight: "700",
    color: theme.palette.background,
    paddingBottom: "15px",
    marginBottom: "15px",
    borderBottom: "1px solid #ddd",
  },
});

const PopperWrapper = styled.div`
  padding: 5px;
`;

const PopperItem = styled.div`
  padding: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd;
  color: #212121;

  button {
    min-width: 0px;
    padding: 4px 8px;
  }
`;

const DateCell = styled.div`
  text-align: right;
`;

const EventItem = styled.div`
  display: block;
  max-width: 125px;
  margin-bottom: 5px;
  margin-left: auto;
  font-size: 12px;
  line-height: 13px;

  ${(props) => {
    if (props.type && props.type == "class") {
      return `
            padding: 4px;
            background: ${theme.palette.primary.main};
            color: #fff;
            `;
    } else if (props.type && props.type == "past_class") {
      return `
            padding: 4px;
            background: ${grey[500]};
            color: #fff;
            `;
    }
  }}
`;

const NavigationButtons = styled.div`
  text-align: right;
  & > select,
  & > button,
  & > a {
    padding: 5px 15px;
    margin-right: 5px;
    margin-bottom: 5px;

    &:last-child() {
      margin-right: 0px;
    }
  }
`;

const CalendarWrapper = styled.div`
  margin: 10px 0 0 0;

  .rc-calendar-full-header,
  .rc-calendar-full-header-switcher {
    display: none;
  }
  .rc-calendar-fullscreen .rc-calendar-cell {
    width: calc(100% / 7);
  }
`;

class ClassCalendar extends React.Component {
  utcOffSet = 8;

  state = {
    selectedDate: moment().utcOffset(this.utcOffSet),
    openViewModal: false,
    modalType: "edit",
    modalData: false,
    openRepeatModal: false,
    repeatData: false,
    openDeleteModal: false,
    deleteModal: false,
    openPopOver: false,
    popoverAnchorEL: {},
    popoverSelect: false,
    randNum: false,
  };

  handleRepeatFormUpdate = (newValue, key, subkey, subVal) => {
    const { onUpdate, schedule } = this.props;
    const { repeatData } = this.state;
    let newData =
      repeatData && !_isEmpty(repeatData) ? cloneCollections(repeatData) : {};
    switch (key) {
      case "start_date":
        newData.start_date =
          schedule && schedule.start_date
            ? moment(newValue)
                .utcOffset(this.utcOffSet)
                .set({
                  hour: moment(schedule.start_date)
                    .utcOffset(this.utcOffSet)
                    .get("hour"),
                  minute: moment(schedule.start_date)
                    .utcOffset(this.utcOffSet)
                    .get("minute"),
                  second: 0,
                  millisecond: 0,
                })
                .valueOf()
            : 0;
        break;
      case "end_date":
        newData.end_date =
          schedule && schedule.end_date
            ? moment(newValue)
                .utcOffset(this.utcOffSet)
                .set({
                  hour: moment(schedule.end_date)
                    .utcOffset(this.utcOffSet)
                    .get("hour"),
                  minute: moment(schedule.end_date)
                    .utcOffset(this.utcOffSet)
                    .get("minute"),
                  second: 0,
                  millisecond: 0,
                })
                .valueOf()
            : 0;
        break;
      case "showcase":
      case "quarterly_report":
        if (
          subkey &&
          (subkey === "checked" || subkey === "unchecked") &&
          subVal &&
          !_isEmpty(subVal)
        ) {
          // use subVal as the new value
          newData[key] = subVal;
        }
        break;
      default:
        newData[key] = newValue;
        break;
    }

    // do update
    this.setState({ repeatData: newData });
  };

  handleFormUpdate = (newValue, key, subkey, subVal) => {
    const { onUpdate, schedule } = this.props;
    const { modalData } = this.state;
    let newData =
      modalData && !_isEmpty(modalData) ? cloneCollections(modalData) : {};
    switch (key) {
      case "class_date":
        newData.start_date =
          schedule && schedule.start_date
            ? moment(newValue)
                .utcOffset(this.utcOffSet)
                .set({
                  hour: moment(schedule.start_date)
                    .utcOffset(this.utcOffSet)
                    .get("hour"),
                  minute: moment(schedule.start_date)
                    .utcOffset(this.utcOffSet)
                    .get("minute"),
                  second: 0,
                  millisecond: 0,
                })
                .valueOf()
            : 0;
        newData.end_date =
          schedule && schedule.end_date
            ? moment(newValue)
                .utcOffset(this.utcOffSet)
                .set({
                  hour: moment(schedule.end_date)
                    .utcOffset(this.utcOffSet)
                    .get("hour"),
                  minute: moment(schedule.end_date)
                    .utcOffset(this.utcOffSet)
                    .get("minute"),
                  second: 0,
                  millisecond: 0,
                })
                .valueOf()
            : 0;
        break;
      case "showcase":
      case "quarterly_report":
        if (
          subkey &&
          (subkey === "checked" || subkey === "unchecked") &&
          subVal &&
          !_isEmpty(subVal)
        ) {
          // use subVal as the new value
          newData[key] = subVal;
        }
        break;
      default:
        newData[key] = newValue;
        break;
    }

    // do update
    this.setState({ modalData: newData });
  };

  handleRepeatAction = () => {
    const { onUpdate, schedule } = this.props;
    const { repeatData } = this.state;
    let error = false,
      today = moment().utcOffset(this.utcOffSet).startOf("date").valueOf();

    if (!(repeatData.name && !_isEmpty(repeatData.name)))
      error = "Please insert a Title";

    // make sure date is valid
    if (repeatData.start_date > repeatData.end_date) {
      error = "Start date cannot be after End date.";
    }

    if (repeatData.end_date < repeatData.start_date) {
      error = "End date cannot be before Start date.";
    }

    if (!(repeatData.start_date >= today))
      error = "Class Date cannot be before today.";

    if (!(repeatData.repeat_on && !_isEmpty(repeatData.repeat_on)))
      error =
        "Please select a day when the class will be repeat on every week.";

    // make sure the difference between start date and end date is not more than 3 months
    // if (
    //   repeatData.end_date - repeatData.start_date >
    //   3 * 30 * 24 * 60 * 60 * 1000
    // ) {
    //   error =
    //     "The difference between start date and end date cannot be more than 3 months.";
    // }

    let newClassCalendar =
      schedule &&
      schedule.class_calendar &&
      isArrayExists(schedule.class_calendar)
        ? cloneCollections(schedule.class_calendar)
        : [];

    // generate repeat classes
    newClassCalendar = this.generateClasses(newClassCalendar);

    if (!isArrayExists(newClassCalendar)) {
      triggerErrorAlert("No Valid Date Found.");
    } // end - class_calendar

    if (error) {
      triggerErrorAlert(error);
    } else {
      // close modal
      this.setState({ openRepeatModal: false, repeatData: false });

      // do update
      if (onUpdate) onUpdate(newClassCalendar, "class_calendar");
    } // end error
  };

  handleSessionAction = () => {
    const { onUpdate, schedule } = this.props;
    const { modalData, modalType } = this.state;
    let today = moment().utcOffset(this.utcOffSet).startOf("date").valueOf(),
      error = false,
      newClassCalendar =
        schedule &&
        schedule.class_calendar &&
        isArrayExists(schedule.class_calendar)
          ? cloneCollections(schedule.class_calendar)
          : [];

    if (!(modalData.start_date >= today))
      error = "Class Date cannot be before today.";

    if (error) {
      triggerErrorAlert(error);
    } else {
      // update event
      if (modalType && modalType === "edit") {
        let index =
          modalData && modalData.id
            ? _findIndex(newClassCalendar, { id: modalData.id })
            : -1;
        newClassCalendar[index] = cloneCollections(modalData);
      } else {
        newClassCalendar.push(modalData);
      } // end - modalType

      // close modal
      this.setState({
        openViewModal: false,
        modalType: "add",
        modalData: false,
      });

      // do update
      if (onUpdate) onUpdate(newClassCalendar, "class_calendar");
    } // end - error
  };

  handleSessionDelete = () => {
    const { onUpdate, schedule } = this.props;
    const { deleteModal } = this.state;
    let newClassCalendar =
        schedule &&
        schedule.class_calendar &&
        isArrayExists(schedule.class_calendar)
          ? cloneCollections(schedule.class_calendar)
          : [],
      index =
        deleteModal && deleteModal.id
          ? _findIndex(newClassCalendar, { id: deleteModal.id })
          : -1;

    if (index >= 0) {
      let pulled = _remove(newClassCalendar, { id: deleteModal.id });
    } // end - index

    // close modal
    this.setState({ openDeleteModal: false, deleteModal: false });

    // do update
    if (onUpdate) onUpdate(newClassCalendar, "class_calendar");
  };

  handleSessionAddNew = () => {
    const { schedule } = this.props;
    const todayYear = moment().utcOffset(this.utcOffSet).get("year"),
      todayMonth = moment().utcOffset(this.utcOffSet).get("month"),
      todayDay = moment().utcOffset(this.utcOffSet).get("date"),
      start_date = moment(schedule.start_date)
        .utcOffset(this.utcOffSet)
        .set({
          year: todayYear,
          month: todayMonth,
          date: todayDay,
          second: 0,
          millisecond: 0,
        })
        .valueOf(),
      end_date = moment(schedule.end_date)
        .utcOffset(this.utcOffSet)
        .set({
          year: todayYear,
          month: todayMonth,
          date: todayDay,
          second: 0,
          millisecond: 0,
        })
        .valueOf();
    this.setState({
      openViewModal: true,
      modalData: this.getNewEventProps(start_date, end_date),
      modalType: "add",
    });
  };

  generateClasses = (class_calendar) => {
    const { schedule, regions, locations, courses } = this.props;
    const { repeatData } = this.state;
    let selectedDay = repeatData.repeat_on
      ? _find(DAY_OPTIONS, { value: repeatData.repeat_on })
      : false; // get selected day option;

    // make sure start date and end date is set
    if (
      repeatData &&
      repeatData.start_date &&
      repeatData.end_date &&
      selectedDay
    ) {
      let endDate = moment(repeatData.end_date)
          .utcOffset(this.utcOffSet)
          .endOf("isoWeek"),
        startDate = moment(repeatData.start_date)
          .utcOffset(this.utcOffSet)
          .startOf("isoWeek"),
        totalWeeks = endDate.diff(startDate, "weeks");
      // run through total weeks to set class_calendar
      for (let i = 0; i <= totalWeeks; i++) {
        // calculate startofweek
        let startofWeek = moment(startDate)
          .utcOffset(this.utcOffSet)
          .set({
            isoWeekday: selectedDay.number,
            hour: moment(schedule.start_date).utcOffset(this.utcOffSet).hour(),
            minute: moment(schedule.start_date)
              .utcOffset(this.utcOffSet)
              .minute(),
            millisecond: 0,
            second: 0,
          })
          .add(i * 7, "day");

        // make sure it doesn't go backforward & go over end date
        if (
          startofWeek.valueOf() >= repeatData.start_date &&
          startofWeek.valueOf() <= repeatData.end_date
        ) {
          let sessionStart = startofWeek.valueOf(),
            sessionEnd = startofWeek
              .set({
                hour: moment(schedule.end_date)
                  .utcOffset(this.utcOffSet)
                  .hour(),
                minute: moment(schedule.end_date)
                  .utcOffset(this.utcOffSet)
                  .minute(),
                millisecond: 0,
                second: 0,
              })
              .valueOf(),
            event = this.getNewEventProps(sessionStart, sessionEnd);

          event.name = repeatData.name || "";
          event.quarterly_report = repeatData.quarterly_report || "";
          event.showcase = repeatData.showcase || "";
          event.remarks = repeatData.remarks || "";
          event.description = repeatData.description || "";

          class_calendar.push(event);
        } // end - startofWeek
      } // end - for
    } // end - schedule

    return class_calendar;
  };

  handleDateCellClicked = (date, events) => (event) => {
    if (events && isArrayExists(events) && events[0].type === "class") {
      this.setState({
        openPopOver: true,
        popoverAnchorEL: event.currentTarget,
        popoverSelect: { date, events },
      });
    } // end - count
  };

  getGoogleCalendarEvents = (date) => {
    const { calendar_events } = this.props;
    const selected = [],
      today = date.utcOffset(this.utcOffSet).startOf("date").valueOf();
    if (calendar_events && isArrayExists(calendar_events)) {
      _forEach(calendar_events, (event) => {
        let start_date =
            event &&
            event.start &&
            event.start.date &&
            !_isEmpty(event.start.date)
              ? moment(event.start.date)
                  .utcOffset(this.utcOffSet)
                  .startOf("date")
                  .valueOf()
              : 0,
          end_date =
            event && event.end && event.end.date && !_isEmpty(event.end.date)
              ? moment(event.end.date)
                  .utcOffset(this.utcOffSet)
                  .endOf("date")
                  .valueOf()
              : 0;
        if (today >= start_date && today <= end_date) {
          selected.push({
            id: event.id,
            name: event.summary || "",
            type: "holiday",
          });
        } // end - today
      });
    } // end - calendar_events
    return selected;
  };

  getSelectedDateEvents = (date) => {
    const { schedule } = this.props;
    return schedule &&
      schedule.class_calendar &&
      isArrayExists(schedule.class_calendar)
      ? _filter(schedule.class_calendar, (e) => {
          return e.start_date >=
            date.utcOffset(this.utcOffSet).startOf("date").valueOf() &&
            e.end_date <= date.utcOffset(this.utcOffSet).endOf("date").valueOf()
            ? true
            : false;
        })
      : [];
  };

  getNewEventProps = (start_date = 0, end_date = 0) => {
    const { schedule } = this.props;
    let event = getSchemaDefaultValue(classCalendarSchema, "add");
    event.id = shortid.generate();
    event.name =
      schedule && schedule.course_name && !_isEmpty(schedule.course_name)
        ? schedule.course_name
        : "Class";
    event.start_date = start_date;
    event.end_date = end_date;
    event.type = "class";
    return event;
  };

  getNewSingleEventProps = (
    start_date = moment().utcOffset(this.utcOffSet).startOf("date").valueOf()
  ) => {
    const { schedule } = this.props;
    let event = getSchemaDefaultValue(classCalendarSchema, "add");
    event.id = shortid.generate();
    event.name =
      schedule && schedule.course_name && !_isEmpty(schedule.course_name)
        ? schedule.course_name
        : "Class";
    event.start_date = moment(start_date)
      .utcOffset(this.utcOffSet)
      .set({
        hour: moment(schedule.start_date).utcOffset(this.utcOffSet).get("hour"),
        minute: moment(schedule.start_date)
          .utcOffset(this.utcOffSet)
          .get("minute"),
        second: 0,
        millisecond: 0,
      })
      .valueOf();
    event.end_date = moment(start_date)
      .utcOffset(this.utcOffSet)
      .set({
        hour: moment(schedule.end_date).utcOffset(this.utcOffSet).get("hour"),
        minute: moment(schedule.end_date)
          .utcOffset(this.utcOffSet)
          .get("minute"),
        second: 0,
        millisecond: 0,
      })
      .valueOf();
    event.type = "class";
    return event;
  };

  renderEditForm = () => {
    const { schedule } = this.props;
    const { modalData, modalType } = this.state;
    const afterThreeMonths = moment()
      .utcOffset(this.utcOffSet)
      .startOf("date")
      .add("months", 3.5)
      .valueOf();
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInput
              label="Title"
              name="name"
              value={modalData.name || ""}
              onChange={this.handleFormUpdate}
            />
          </Grid>
          <Grid item xs={12}>
            <FormDatePicker
              label="Class Date"
              name="class_date"
              noDefaultVal={true}
              disablePast={true}
              disableAfter={afterThreeMonths}
              value={modalData.start_date || 0}
              onChange={this.handleFormUpdate}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTimePicker
              noDefaultVal={true}
              label="Start Time"
              disabled={true}
              value={(schedule && schedule.start_date) || 0}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTimePicker
              noDefaultVal={true}
              label="End Time"
              disabled={true}
              value={(schedule && schedule.end_date) || 0}
            />
          </Grid>
          <Grid item xs={5}>
            <div style={{ padding: "0 5px" }}>
              <FormCheckbox
                label={
                  <>
                    <span style={{ marginRight: "5px" }}>
                      This is a Open Day
                    </span>
                    <ToolTipInfo
                      content={
                        <>
                          If checked, this date will be listed in{" "}
                          <a
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              this.props.history.push("/class_showcase", {
                                schedule_id: (schedule && schedule.id) || "",
                              });
                            }}
                          >
                            Showcase
                          </a>{" "}
                          module
                        </>
                      }
                    />
                  </>
                }
                name="showcase"
                value={modalData.showcase || "no"}
                checked="yes"
                unchecked="no"
                onChange={this.handleFormUpdate}
              />
            </div>
          </Grid>
          <Grid item xs={7}>
            <div style={{ padding: "0 5px" }}>
              <FormCheckbox
                label={
                  <>
                    <span style={{ marginRight: "5px" }}>
                      This is Quarterly Reporting day
                    </span>
                    <ToolTipInfo
                      content={
                        <>
                          If checked, this date will be listed in{" "}
                          <a
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              this.props.history.push(
                                "/class_quarterly_report",
                                {
                                  schedule_id: (schedule && schedule.id) || "",
                                }
                              );
                            }}
                          >
                            Quarterly Report
                          </a>{" "}
                          module
                        </>
                      }
                    />
                  </>
                }
                name="quarterly_report"
                value={modalData.quarterly_report || "no"}
                checked="yes"
                unchecked="no"
                onChange={this.handleFormUpdate}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormInput
              tooltip={<>For internal reference on SMS only.</>}
              label="Remark"
              name="remarks"
              value={modalData.remarks || ""}
              multiline={true}
              rows={3}
              onChange={this.handleFormUpdate}
            />
          </Grid>
          <Grid item xs={12}>
            {/* <FormEditor id={( modalData.id || false )} 
                    tooltip={<>Description will be reflected in LMS Calendar. Learn how to sync LMS calendar <a href="https://community.canvaslms.com/t5/Student-Guide/How-do-I-subscribe-to-the-Calendar-feed-using-Google-Calendar-as/ta-p/535" target="_blank">here</a>.</>}
                    label="Description" name="description" value={( modalData.description || '' )} 
                        height={400}
                        plugins={[
                            'advlist autolink lists link charmap preview hr anchor',
                            'searchreplace visualblocks visualchars code',
                            'insertdatetime media nonbreaking save table directionality',
                            'paste textpattern imagetools toc'
                        ]}
                        toolbar1="undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link" 
                        onChange={this.handleFormUpdate} /> */}
            <FormInput
              tooltip={
                <>
                  Description will be reflected in LMS Calendar. Learn how to
                  sync LMS calendar{" "}
                  <a
                    href="https://community.canvaslms.com/t5/Student-Guide/How-do-I-subscribe-to-the-Calendar-feed-using-Google-Calendar-as/ta-p/535"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </>
              }
              label="Description"
              name="description"
              value={modalData.description || ""}
              multiline={true}
              rows={3}
              onChange={this.handleFormUpdate}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  renderRepeatForm = () => {
    const { schedule } = this.props;
    const { repeatData } = this.state;
    const afterThreeMonths = moment()
      .utcOffset(this.utcOffSet)
      .startOf("date")
      .add("months", 3.5)
      .valueOf();
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormDatePicker
              label="From"
              name="start_date"
              required="*"
              noDefaultVal={true}
              disablePast={true}
              disableAfter={afterThreeMonths}
              value={repeatData.start_date || 0}
              onChange={this.handleRepeatFormUpdate}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDatePicker
              label="Until"
              name="end_date"
              required="*"
              noDefaultVal={true}
              disablePast={true}
              // disable any date after 3 months from start date
              disableAfter={afterThreeMonths}
              value={repeatData.end_date || 0}
              onChange={this.handleRepeatFormUpdate}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTimePicker
              noDefaultVal={true}
              label="Start Time"
              disabled={true}
              value={(schedule && schedule.start_date) || 0}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTimePicker
              noDefaultVal={true}
              label="End Time"
              disabled={true}
              value={(schedule && schedule.end_date) || 0}
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              label="Repeat on Every"
              name="repeat_on"
              required="*"
              value={repeatData.repeat_on || ""}
              options={getSelectOptions({
                list: DAY_OPTIONS,
                options: [{ value: "", label: "Select an Option" }],
                keys: { value: "value", label: "label" },
              })}
              onChange={this.handleRepeatFormUpdate}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
          </Grid>

          <Grid item xs={12}>
            <FormInput
              label="Title"
              name="name"
              required="*"
              value={repeatData.name || ""}
              onChange={this.handleRepeatFormUpdate}
            />
          </Grid>
          <Grid item xs={5}>
            <div style={{ padding: "0 5px" }}>
              <FormCheckbox
                label={
                  <>
                    <span style={{ marginRight: "5px" }}>
                      This is a Open Day
                    </span>
                    <ToolTipInfo
                      content={
                        <>
                          If checked, this date will be listed in{" "}
                          <a
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              this.props.history.push("/class_showcase", {
                                schedule_id: (schedule && schedule.id) || "",
                              });
                            }}
                          >
                            Showcase
                          </a>{" "}
                          module
                        </>
                      }
                    />
                  </>
                }
                name="showcase"
                value={repeatData.showcase || "no"}
                checked="yes"
                unchecked="no"
                onChange={this.handleRepeatFormUpdate}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ padding: "0 5px" }}>
              <FormCheckbox
                label={
                  <>
                    <span style={{ marginRight: "5px" }}>
                      This is Quarterly Reporting day
                    </span>
                    <ToolTipInfo
                      content={
                        <>
                          If checked, this date will be listed in{" "}
                          <a
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              this.props.history.push(
                                "/class_quarterly_report",
                                {
                                  schedule_id: (schedule && schedule.id) || "",
                                }
                              );
                            }}
                          >
                            Quarterly Report
                          </a>{" "}
                          module
                        </>
                      }
                    />
                  </>
                }
                name="quarterly_report"
                value={repeatData.quarterly_report || "no"}
                checked="yes"
                unchecked="no"
                onChange={this.handleRepeatFormUpdate}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormInput
              label="Remark"
              name="remarks"
              value={repeatData.remarks || ""}
              multiline={true}
              rows={3}
              onChange={this.handleRepeatFormUpdate}
            />
          </Grid>
          <Grid item xs={12}>
            {/* <FormEditor id={( repeatData.id || false )} label="Description" name="description" value={( repeatData.description || '' )} 
                        height={400} 
                        plugins={[
                            'advlist autolink lists link charmap preview hr anchor',
                            'searchreplace visualblocks visualchars code',
                            'insertdatetime media nonbreaking save table directionality',
                            'paste textpattern imagetools toc'
                        ]}
                        toolbar1="undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link"
                        onChange={this.handleRepeatFormUpdate} /> */}
            <FormInput
              tooltip={
                <>
                  Description will be reflected in LMS Calendar. Learn how to
                  sync LMS calendar{" "}
                  <a
                    href="https://community.canvaslms.com/t5/Student-Guide/How-do-I-subscribe-to-the-Calendar-feed-using-Google-Calendar-as/ta-p/535"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </>
              }
              label="Description"
              name="description"
              value={repeatData.description || ""}
              multiline={true}
              rows={3}
              onChange={this.handleRepeatFormUpdate}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  renderPopperMenu = () => {
    const { popoverSelect } = this.state;
    const { date = false, events = [] } = popoverSelect;
    return (
      <PopperWrapper style={{ padding: "5px" }}>
        {events && isArrayExists(events)
          ? events.map((event) => {
              return (
                <PopperItem>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      {event.name || "Class"}
                    </Grid>
                    <Grid item xs={6}>
                      <ButtonGroup align="right">
                        <SuccessButton
                          noIconMargin="yes"
                          onClick={() =>
                            this.setState({
                              openViewModal: true,
                              modalData: event,
                              modalType: "edit",
                            })
                          }
                        >
                          <i className="fa fa-edit"></i>
                        </SuccessButton>
                        <ErrorButton
                          noIconMargin="yes"
                          onClick={() =>
                            this.setState({
                              openDeleteModal: true,
                              deleteModal: event,
                            })
                          }
                        >
                          <i className="fa fa-trash"></i>
                        </ErrorButton>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </PopperItem>
              );
            })
          : ""}
      </PopperWrapper>
    );
  };

  renderEventItem = (event) => {
    return (
      <EventItem id={event.id} key={event.id} type={event.type || "class"}>
        {/* <WrapWord>{event.name || 'Class'}</WrapWord> */}
        {/* <span>{event.name || 'Class'}</span> */}
        {event.name || "Class"}
      </EventItem>
    );
  };

  renderDateCell = (date) => {
    const events = this.getSelectedDateEvents(date),
      holidays = this.getGoogleCalendarEvents(date),
      list = [...events, ...holidays];
    return (
      <DateCell onClick={this.handleDateCellClicked(date, events)}>
        {date.utcOffset(this.utcOffSet).format("D")}
        {list && isArrayExists(list) ? list.map(this.renderEventItem) : null}
      </DateCell>
    );
  };

  renderCalendar = () => {
    const { selectedDate } = this.state;
    return (
      <CalendarWrapper>
        <FullCalendar
          Select={RCSelect}
          type="date"
          defaultType="date"
          fullscreen={true}
          value={selectedDate}
          dateCellContentRender={this.renderDateCell}
          onSelect={(date) => this.setState({ selectedDate: date })}
        />
      </CalendarWrapper>
    );
  };

  renderYearMonthSelector = () => {
    const { selectedDate } = this.state;
    let currentYear = moment().utcOffset(this.utcOffSet).year(),
      years = [];
    for (let i = currentYear - 10; i < currentYear; i++) {
      years.push({ value: i.toString(), label: i.toString() });
    }
    for (let j = currentYear; j < currentYear + 3; j++) {
      years.push({ value: j.toString(), label: j.toString() });
    }
    return (
      <>
        <select
          value={_toString(selectedDate.year())}
          onChange={(event) =>
            this.setState({
              selectedDate: selectedDate.set({
                year: parseInt(event.target.value, 10),
              }),
            })
          }
        >
          {years.map((year) => {
            return (
              <option key={year.value} value={year.value}>
                {year.label}
              </option>
            );
          })}
        </select>
        <select
          value={_toString(selectedDate.month())}
          onChange={(event) =>
            this.setState({
              selectedDate: selectedDate.set({
                month: parseInt(event.target.value, 10),
              }),
            })
          }
        >
          {MONTH_OPTIONS.map((month) => {
            return (
              <option key={month.label} value={month.value}>
                {month.label}
              </option>
            );
          })}
        </select>
      </>
    );
  };

  renderNavigationButtons = () => {
    const { selectedDate } = this.state;
    return (
      <NavigationButtons>
        {this.renderYearMonthSelector()}
        <InverseButton
          minWidth="0px"
          noIconMargin="yes"
          onClick={() =>
            this.setState({ selectedDate: selectedDate.subtract(1, "months") })
          }
        >
          <i className="fa fa-caret-left"></i>
        </InverseButton>
        <InverseButton
          minWidth="0px"
          noIconMargin="yes"
          onClick={() =>
            this.setState({ selectedDate: selectedDate.add(1, "months") })
          }
        >
          <i className="fa fa-caret-right"></i>
        </InverseButton>
      </NavigationButtons>
    );
  };

  renderActionButtons = () => {
    const { schedule } = this.props;
    let today = moment().utcOffset(this.utcOffSet).startOf("date").valueOf(),
      start_date =
        schedule && schedule.start_date && schedule.start_date >= today
          ? schedule.start_date
          : today;
    return (
      <ButtonActions
        key="actions"
        label="Actions"
        menuContainerStyle={{ width: "200px" }}
        style={{ padding: "10px 15px" }}
        wrapperStyle={{ display: "inline-block", marginRight: "5px" }}
        actions={[
          {
            id: "addnew",
            label: "Add New Class",
            icon: "fa-plus-circle",
            onClick: () =>
              this.setState({
                openViewModal: true,
                modalData: schedule
                  ? this.getNewSingleEventProps(start_date)
                  : this.getNewSingleEventProps(),
                modalType: "new",
              }),
          },
          {
            id: "addrepeat",
            label: "Add Repeat Classes",
            icon: "fa-plus-circle",
            onClick: () =>
              this.setState({
                openRepeatModal: true,
                repeatData: {
                  start_date,
                  end_date:
                    schedule && schedule.start_date
                      ? moment(schedule.start_date)
                          .utcOffset(this.utcOffSet)
                          .endOf("month")
                          .valueOf()
                      : moment()
                          .utcOffset(this.utcOffSet)
                          .endOf("month")
                          .valueOf(),
                  repeat: "weekly",
                  repeat_on: "",
                  name:
                    schedule &&
                    schedule.course_name &&
                    !_isEmpty(schedule.course_name)
                      ? schedule.course_name
                      : "Class",
                  quarterly_report: "no",
                  showcase: "no",
                  remarks: "",
                  description: "",
                },
              }),
          },
        ]}
      />
    );
  };

  render = () => {
    const { classes, schedule, formType } = this.props;
    const {
      openDeleteModal,
      deleteModal,
      openViewModal,
      modalType,
      openRepeatModal,
      openPopOver,
      popoverAnchorEL,
    } = this.state;
    return (
      <>
        <Grid container spacing={1} alignItems="center">
          {/* { !( schedule && schedule.class_calendar && isArrayExists( schedule.class_calendar ) && _find( schedule.class_calendar, { showcase: 'yes' } ) ) ? (
                <Grid item xs={12} align="right">
                    <ErrorBox inline={true} style={{ fontSize: '18px' }}>Warning! You have yet to schedule any Open Day in Class Calendar. </ErrorBox>
                </Grid>
                ) : null } */}
          <Grid item xs={12} sm={6}>
            {this.renderActionButtons()}
            <ToolTipInfo
              content={
                <>
                  Add your classes into this calendar and they will sync with
                  the LMS calendar for students & mentors.
                </>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* {this.renderYearMonthSelector()} */}
            {this.renderNavigationButtons()}
          </Grid>
        </Grid>

        {this.renderCalendar()}

        <PopperBox
          open={openPopOver}
          placement="bottom"
          anchorEl={popoverAnchorEL}
          onClose={() =>
            this.setState({
              openPopOver: false,
              popoverAnchorEL: {},
              popoverSelect: false,
            })
          }
          wrapperStyle={{ paddingTop: "10px" }}
          containerStyle={{ width: "250px" }}
          zIndex="99"
          contents={this.renderPopperMenu()}
        />

        <ModalView
          open={openViewModal}
          title={
            modalType && modalType === "new"
              ? "Add New Class"
              : "Edit Class Calendar"
          }
          disableAutoFocus={true}
          disableEnforceFocus={true}
          actionLabel={modalType && modalType == "edit" ? "Update" : "Add New"}
          onClose={() =>
            this.setState({ openViewModal: false, modalData: false })
          }
          doAction={this.handleSessionAction}
          contents={this.renderEditForm()}
        />

        <ModalView
          open={openRepeatModal}
          title="Add Repeat Classes"
          disableAutoFocus={true}
          disableEnforceFocus={true}
          actionLabel="Add New Classes"
          onClose={() =>
            this.setState({ openRepeatModal: false, repeatData: false })
          }
          doAction={this.handleRepeatAction}
          contents={this.renderRepeatForm()}
        />

        <ModelDelete
          open={openDeleteModal}
          title={
            deleteModal && deleteModal.start_date
              ? `Are you sure you want to delete this class ( ${getMomentTime(
                  deleteModal.start_date,
                  "DD MMM YYYY"
                )} )?`
              : false
          }
          onClose={() =>
            this.setState({ openDeleteModal: false, deleteModal: false })
          }
          onDelete={this.handleSessionDelete}
        />
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    authData: state.auth && state.auth.user ? state.auth.user : null,
    changesMade:
      state.misc && state.misc.changes_made ? state.misc.changes_made : null,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(useStyles),
  withRouter
)(ClassCalendar);
