/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import red from "@material-ui/core/colors/red";
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from "lodash/isEmpty";

import theme from "../../theme";

const useStyles = (theme) => ({
  paperScrollPaper: {
    background: "#fff",
  },
  deleteButton: {
    color: red[500],
    backgroundColor: "none",
    "&:hover": {
      backgroundColor: red[500],
      color: "#fff",
    },
  },
  deletingIcon: {
    color: red[500],
  },
});

class ModelDelete extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { modalDeleting, randNum } = this.props;
    if (
      !modalDeleting &&
      !randNum &&
      prevProps &&
      prevProps.modalDeleting &&
      this.props.onClose
    ) {
      this.props.onClose();
    }
  }

  handleClose = () => {
    if (this.props.onClose) this.props.onClose();
  };

  handleDelete = () => {
    if (this.props.onDelete) this.props.onDelete();
  };

  isProcessing = () => {
    const { processing, modalDeleting } = this.props;
    return processing || modalDeleting ? true : false;
  };

  renderActions = () => {
    const {
      classes,
      deleteLabel,
      cancelLabel = "Cancel",
      hideDeleteButton = false,
    } = this.props;
    return (
      <DialogActions>
        <Button onClick={this.handleClose} color="primary">
          {cancelLabel}
        </Button>
        {!hideDeleteButton && (
          <Button onClick={this.handleDelete} className={classes.deleteButton}>
            {deleteLabel || "Delete"}
          </Button>
        )}
      </DialogActions>
    );
  };

  renderProcessing = () => {
    const { classes, deletingLabel } = this.props;
    return (
      <DialogActions>
        <Button className={classes.deleteButton}>
          <CircularProgress
            size={14}
            className={classes.deletingIcon}
            style={{ marginRight: "8px" }}
          />
          {deletingLabel || "Deleting..."}
        </Button>
      </DialogActions>
    );
  };

  renderContent = () => {
    const { content, fullContent } = this.props;
    return (
      <DialogContent>
        {fullContent && !_isEmpty(fullContent) ? (
          fullContent
        ) : (
          <DialogContentText id="alert-dialog-description">
            {content && !_isEmpty(content)
              ? content
              : "This action cannot be reversed."}
          </DialogContentText>
        )}
      </DialogContent>
    );
  };

  renderTitle = () => {
    const { title } = this.props;
    return (
      <DialogTitle>
        {title && !_isEmpty(title)
          ? title
          : "Are you sure you want to delete this item?"}
      </DialogTitle>
    );
  };

  render() {
    const { open, title, content, classes } = this.props;
    return (
      <Dialog
        open={open}
        classes={{ paperScrollPaper: classes.paperScrollPaper }}
        disableBackdropClick={this.isProcessing()}
        disableEscapeKeyDown={this.isProcessing()}
        onClose={this.handleClose}
      >
        {this.renderTitle()}
        {this.renderContent()}
        {this.isProcessing() ? this.renderProcessing() : this.renderActions()}
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modalDeleting: (state.global && state.global.modal_deleting) || null,
    randNum: (state.global && state.global.modal_deleting_rand) || null,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(useStyles)
)(ModelDelete);
